/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
(function ($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {


                /* CUSTOM JS MOVED TO FILE */






                // VESTIGIAL - NO LONGER A SUBMENU AND NO LONGER NEEDS REPOSITIONING
                //submenuPosition();
                // $(window).resize(function () {

                //     submenuPosition();


                //      if ($(window).width() > 1024) {
                //         skrollr.init();  //REMOVE?


                //      }

                // });

                // function submenuPosition() {

                //     //REMOVE
                //     var headerHeight = $('header').outerHeight();
                //     $("<style type='text/css'>.navigation .menu>li ul.sub-menu { top: " + headerHeight + "px} .banner-title, .banner-w-img{ margin-top: " + headerHeight + "px}</style>").appendTo('head');




                // }


                //REMOVE
                // if ($(window).width() > 768) {
                //     submenuPadding()

                //     $(window).resize(function () {

                //         submenuPadding()
                //     });


                // }


                // $('.mobile-menu-container .menu-item-has-children > a').each(function(){

                //     var link = $(this).attr('href');
                //     var item = $(this).text();
                //     $(this).parents('.menu-item-has-children').find('.sub-menu').prepend('<li><a href="'+ link +'">' + item + '</a></li>');


                // });

                //REMOVE
                // function submenuPadding() {


                //     var containerWidth = $('body').find('.container').first().outerWidth();
                //     var windowWidth = $(window).width();
                //     var half = ((windowWidth - containerWidth) / 2) + 15;

                //     $("<style type='text/css'>.navigation .menu>li.services-menu .sub-menu{ padding: 0 " + half + "px}</style>").appendTo('head');


                // }



            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired


                $(document).ready(function () {

                    if ($(".ie").length || $('.edge').length) {

                        $('.section-every-year .panel-outer img').each(function () {

                            var imgposition = $(this).css('background-position');


                            //FIND WHERE THIS WORKS
                            if ($(this).hasClass('.grayscale')) {

                                //						var el = $(this);

                                function grayscale(src) {
                                    var canvas = document.createElement('canvas');
                                    var ctx = canvas.getContext('2d');
                                    var imgObj = new Image();
                                    imgObj.src = src;
                                    canvas.width = imgObj.width;
                                    canvas.height = imgObj.height;
                                    ctx.drawImage(imgObj, 0, 0);
                                    var imgPixels = ctx.getImageData(0, 0, canvas.width, canvas.height);
                                    for (var y = 0; y < imgPixels.height; y++) {
                                        for (var x = 0; x < imgPixels.width; x++) {
                                            var i = (y * 4) * imgPixels.width + x * 4;
                                            var avg = (imgPixels.data[i] + imgPixels.data[i + 1] + imgPixels.data[i + 2]) / 3;
                                            imgPixels.data[i] = avg;
                                            imgPixels.data[i + 1] = avg;
                                            imgPixels.data[i + 2] = avg;
                                        }
                                    }
                                    ctx.putImageData(imgPixels, 0, 0, 0, 0, imgPixels.width, imgPixels.height);
                                    return canvas.toDataURL();
                                }


                                if (imgposition == true) {

                                    $(this).attr('style', 'background-image:url(' + grayscale(this.src) + '); background-position: ' + imgposition + '; color:rgba(0,0,0,0);').attr('src', '/wp-content/themes/chs-childrens-home-society-web/dist/img/clear.gif');


                                } else {


                                    $(this).attr('style', 'background-image:url(' + grayscale(this.src) + '); background-position: 50%; color:rgba(0,0,0,0);').attr('src', '/wp-content/themes/chs-childrens-home-society-web/dist/img/clear.gif');


                                }



                            } else {

                                var image = $(this).attr('src');

                                if (imgposition == true) {

                                    $(this).attr('style', 'background-image:url(' + image + '); background-position: ' + imgposition + '; color:rgba(0,0,0,0);').attr('src', '/wp-content/themes/chs-childrens-home-society-web/dist/img/clear.gif');

                                } else {


                                    $(this).attr('style', 'background-image:url(' + grayscale(this.src) + '); background-position: 50%; color:rgba(0,0,0,0);').attr('src', '/wp-content/themes/chs-childrens-home-society-web/dist/img/clear.gif');


                                }

                            }







                        });
                        $('.corner-image, .circle-image, .modal-image img, .headshot, .img-col-service img, .affiliation-logo, .sponsor-logo, .img-col-leaders img').each(function () {

                            var image = $(this).attr('src');


                            $(this).attr('style', 'background-image:url(' + image + ');  color:rgba(0,0,0,0);').attr('src', '/wp-content/themes/chs-childrens-home-society-web/dist/img/clear.gif');



                        });






                    }


                })


                $('.pop-image').magnificPopup({
                    type: 'image',
                    mainClass: 'mfp-fade',
                    removalDelay: 300,
                    gallery: {
                        enabled: true
                    }

                });

                //Menu Toggle DEPRECATED

                //$('.menu-toggle').click(function () {

                    //$('body').toggleClass('menu-open');
                    //$('body').removeClass('search-active');
                    //$('body').trigger('click');
//                    $('.search-list.search-active').removeClass('search-active').slideUp(500);
                    //return false;

                //});


                //Accordion from WYSIWYG FIX

                $('.chs-accordion').each(function () {


                    $(this).before('<a href="#" class="chs-btn-blue accordion-toggle">Read More</a>');
                    $(this).slideUp(300);

                });

                //PR Cookie

                $('.chs-btn-brown.block.pr-cookie').click(function(){

                    var link = $(this).attr('href');

                    Cookies.set('viewing-pr', 'yes');
                    console.log(Cookies.get('viewing-pr'));


                    setTimeout(function(){
                    window.location=link
                    }, 500);

                    return false;


                });

                $('.accordion-toggle').click(function(){

                    $(this).next('.chs-accordion').slideToggle(300);
                   return false;

                });

                //Sub Menu - Mobile

                $('.mobile-menu-container .menu li.menu-item-has-children > a').click(function () {

                    $(this).parents('li').find('.sub-menu').slideToggle(300);
                    return false;

                });

                if ($('.ios.ipad.orientation_landscape').length) {

                    $('.menu-item-has-children').click(function (e) {


                        $(this).addClass('hover');

                    });


                }


                // REMOVED TO FIX STICKY FORM ISSUE
                // var countyoptions = {
                //     valueNames: ['county-link']
                // };

                // var countyList = new List('county-list', countyoptions);

                // countyList.sort('county-link', {
                //     order: "asc"
                // });  

               var siteoptions = {
                   valueNames: ['site-link']
               };

               var desktopsearchList = new List('desktop-site-search', siteoptions);

               var mobilesearchList = new List('mobile-site-search', siteoptions);

                $("#desktop-site-search .site-input").keyup(function (e) {

                    e.stopPropagation();

                    desktopsearchList.search($(this).val());

                  $(this).parents('.site-search').find('.search-list').addClass('search-active');






                });
                $("#mobile-site-search .site-input").keyup(function (e) {

                    e.stopPropagation();

                    mobilesearchList.search($(this).val());

                        $(this).parents('.site-search').find('.search-list').addClass('search-active').slideDown(500);



                });


                if($('.home').length){


                $('#site-search input').click(function(){

                    $('body').addClass('menu-open');
                    $('body').addClass('search-active');

                    $('html, body').animate({
                        scrollTop: $('.fp-searchabout').offset().top - 80
                    }, 0);

//                    });


                    $('#site-search input').focus();
                    $('body').addClass('search-active');

                    return false;

                });


                } else {

                $('#site-search input').click(function(){

                    $('body').addClass('search-active');

                    return false;

                });


                }


                $("html, body").click(function (e) {


                    if ($('.search-list').hasClass('search-active')) {

                        if ($(e.target).hasClass('site-input')) {
                            return false;
                        } else {


                            $('.search-list').removeClass('search-active');
                            $('#search').val('');




                        }

                    }

                });

                window.displayBoxIndex = -1;

                 $("#desktop-site-search input").keyup(function (e) {
                    var NavigateSearch = function (diff) {
                        displayBoxIndex += diff;
                        var oBoxCollection = $(".searchwp-live-search-results > div");
                        if (displayBoxIndex >= oBoxCollection.length)
                            displayBoxIndex = 0;
                        if (displayBoxIndex < 0)
                            displayBoxIndex = oBoxCollection.length - 1;
                        var cssClass = "active";
                        oBoxCollection.removeClass(cssClass).eq(displayBoxIndex).addClass(cssClass);
                    }

                    // if($('.home').length && ($(window).width() > 768)){


                    //                 $.fn.fullpage.setAllowScrolling(false);


                    // }

                    $('body').addClass('search-active');


                    if (e.keyCode == 40) {
                        NavigateSearch(1);
                    }
                    if (e.keyCode == 38) {
                        NavigateSearch(-1);
                    }
                    if (e.keyCode == 13) {

                        var link = $(".searchwp-live-search-results > div.active a").attr('href');

                        if(link == undefined){

                        return false;
                        } else {

                        window.location.href = link;

                        }




                    }

                });

//                if($('.search-active').length) {


                    $('.search-overlay').click(function(){

                    if($('.home').length && ($(window).width() > 768)){

                                    // $.fn.fullpage.setAllowScrolling(true);

                        }

                    $('body').removeClass('search-active');


                    });


//                }




                $(document).on('click', '.location_enter span', function (e) {

                    if (Cookies.get('primary-location') == undefined) {

                        $(this).parents('.location_enter').addClass('loading');

                        getCHSLocation();




                    } else {



                        e.stopPropagation();


                        $('.locations-menu').toggleClass('active');
                        $(this).addClass('open');


                        return false;





                    }



                });
                $('.mobile-loc > a').click(function (e) {

                    if (Cookies.get('primary-location') == undefined) {

                        $(this).addClass('loading');

                        getCHSLocation();




                    } else {



                        e.stopPropagation();


                        $('.locations-menu').toggleClass('active');

                        return false;





                    }



                });

                $(document).on('click', '.location_enter .fa-close', function (e) {

                    Cookies.remove('primary-location');
                    Cookies.remove('chs-location');
                    $(".location_enter").html('<span>Find Location</span>');

                    return false;


                });


                $('.socials li a').click(function () {
                    var poplink = $(this).attr('href');
                    newwindow = window.open(poplink, 'name', 'height=800,width=1200');
                    if (window.focus) {
                        newwindow.focus()
                    }
                    return false;

                });


                $("html, body").click(function (e) {


                    if ($('.locations-menu').hasClass('active')) {

                        if ($(e.target).hasClass('search')) {
                            return false;
                        } else {

                            $('.locations-menu').removeClass('active');
                            $('.location_enter span').removeClass('open');


                        }

                    }


                });



                $('.locations-menu .list a').click(function () {

                    var chsRegion = $(this).parent().attr('data-name');
                    var chsRegionURL = $(this).attr('href');
                    var chsRegionPhone = $(this).attr('data-src');
                    var local_county = $(this).parent().attr('data-toggle');

                    Cookies.remove('primary-location');
                    Cookies.set('primary-location', local_county, {
                        expires: 365
                    });
                    Cookies.remove('chs-location');
                    Cookies.set('chs-location', chsRegion, {
                        expires: 365
                    });

                    $(".location_enter").html(chsRegion + ' <i class="fa fa-close"></i>');
                    $(".local-office a").attr('href', chsRegionURL);
                    // $('.phone-btn').attr('href', 'tel:' + chsRegionPhone + '');
                    // $('.phone-btn span').text(chsRegionPhone);
                    // $('.phone-btn').html('Call Your Local Office<span class="number">' + chsRegionPhone + '</span>');
                    $('body').removeClass('no-geo');

                    console.log(local_county);


                });





                $('.search').keypress(function () {
                    $(this).css('border-radius', '0');
                    $('.list').slideDown(500);


                });

                window.searchBoxIndex = -1;
                $(".search").keyup(function (e) {


                    var Navigate = function (diff) {
                        searchBoxIndex += diff;
                        var oBoxCollection = $(".locations-menu .list li");
                        if (searchBoxIndex >= oBoxCollection.length)
                            searchBoxIndex = 0;
                        if (searchBoxIndex < 0)
                            searchBoxIndex = oBoxCollection.length - 1;
                        var cssClass = "active";
                        oBoxCollection.removeClass(cssClass).eq(searchBoxIndex).addClass(cssClass);
                    }


                    if (e.keyCode == 40) {
                        Navigate(1);
                    }
                    if (e.keyCode == 38) {
                        Navigate(-1);
                    }
                    if (e.keyCode == 13) {


                        var local_county = $("li.active a").parent().attr('data-toggle');
                        var chsRegion = $("li.active a").parent().attr('data-name');
                        Cookies.remove('primary-location');
                        Cookies.set('primary-location', local_county, {
                            expires: 365
                        });
                        Cookies.remove('chs-location');
                        Cookies.set('chs-location', chsRegion, {
                            expires: 365
                        });

                        var link = $("li.active a").attr('href');
                        window.location.href = link;




                    }

                });





                $('.video-slider').slick({
                    infinite: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    prevArrow: "<img class='a-right control-c prev slick-prev' src='../../../../wp-content/themes/chs-childrens-home-society-web/dist/img/prev-arrow.svg'>",
                    nextArrow: "<img class='a-right control-c next slick-next' src='../../../../wp-content/themes/chs-childrens-home-society-web/dist/img/right-arrow.svg'>",
                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
    },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
    }

  ]
                });



                //Video On Single

                $('.thumbnail-video').click(function () {
                    var videoURL = $(this).attr('data-url');
                    var videoTitle = $(this).attr('data-title');
                    var videoContent = $(this).attr('data-content');


                });


                //modal on loaction page




                //SKROLLER Init




                $(window).load(function () {

                    //HEADER Location
                    $.get("https://ipinfo.io/json", function (response) {
                        $("#ip").html("IP: " + response.ip);
                        $("#address").html("" + response.city + ", " + response.region);
                        $("#details").html(JSON.stringify(response, null, 4));
                    }, "jsonp");

                    if (Cookies.get('primary-location') == undefined) {





                    } else {



                        getCHSLocation();


                    }


                    //Sliders



                    var logoObj = {
                        "logo_path": {
                            "strokepath": [
                                {
                                    "path": "M23.257,68.813c11.056,0,20.018-8.978,20.018-20.053H23.257V25.355c-11.98,0-21.691,9.728-21.691,21.729  S11.277,68.813,23.257,68.813z",
                                    "duration": 800
            },
                                {
                                    "path": "M34.943,25.699c5.455,0,9.877,4.43,9.877,9.894s-4.422,9.894-9.877,9.894s-9.877-4.43-9.877-9.894  S29.488,25.699,34.943,25.699z",
                                    "duration": 800
            },
                                {
                                    "path": "M66.831,48.76V11.026C66.832,5.489,62.35,1,56.823,1c-5.528,0-10.009,4.489-10.009,10.026v57.777  C57.866,68.803,66.826,59.83,66.831,48.76z",
                                    "duration": 800
            },
                                {
                                    "path": "M79.319,68.803c5.525,0,10.004-4.484,10.009-10.017V45.408c0-11.075-8.962-20.053-20.018-20.053v33.431l0,0  C69.316,64.319,73.795,68.803,79.319,68.803z",
                                    "duration": 800
            },
                                {
                                    "path": "M 82.112,24.6 L 89.787,19.074 97.461,24.66 94.53,15.622 102.204,10.037 92.718,10.037 89.787,1   86.855,10.037 77.369,10.037 85.044,15.622 z",
                                    "duration": 800
            },
                                {
                                    "path": "M96.585,30.964c-7.008,7.02-7.008,18.402,0,25.422l25.378-25.422C114.955,23.944,103.593,23.944,96.585,30.964z  ",
                                    "duration": 800
            },
                                {
                                    "path": "M92.333,63.824c7.008,7.021,18.37,7.021,25.378,0c7.008-7.02,7.008-18.402,0-25.422L92.333,63.824z",
                                    "duration": 800
            }
                            ],
                            "dimensions": {
                                "width": 125,
                                "height": 71
                            }
                        }
                    };



                    $('#logo_path').lazylinepainter({
                        "svgData": logoObj,
                        "strokeWidth": 1.5,
                        "strokeColor": "#F2B027",
                        "drawSequential": false,
                        'onStrokeComplete': function () {
                            setTimeout(function () {
                                $('body').removeClass('loading');
                            }, 500);

                            if ($('.banner-history').length) {
                                setTimeout(function () {
                                    $('.page-template-template-history .banner-history .banner-text').css('opacity', '1');
                                    $('.history-line-1').css('transform', 'scaleX(1)')
                                }, 1000);
                            }


                        }

                    }).lazylinepainter('paint');


                    if ($('.webkit').length) {

                        //SHAPES CODE TO REMOVE
                        particlesJS('footer-shapes',

                            {
                                "particles": {
                                    "number": {
                                        "value": 4
                                    },
                                    "color": {
                                        "value": "#00B5E2"
                                    },
                                    "shape": {
                                        "type": ["fimage", "image2", "image3", "image4"],
                                        "image": {
                                            "src": "/wp-content/themes/chs-childrens-home-society-web/dist/img/shapes/star.svg"
                                        },
                                        "image2": {
                                            "src": "/wp-content/themes/chs-childrens-home-society-web/dist/img/shapes/cir.svg"
                                        },
                                        "image3": {
                                            "src": "/wp-content/themes/chs-childrens-home-society-web/dist/img/shapes/tri.svg"
                                        },
                                        "image4": {
                                            "src": "/wp-content/themes/chs-childrens-home-society-web/dist/img/shapes/square.svg"
                                        }
                                    },
                                    "opacity": {
                                        "value": 1,
                                        "random": false,
                                        "anim": {
                                            "enable": false,
                                            "speed": 1,
                                            "opacity_min": 0.1,
                                            "sync": false
                                        }
                                    },
                                    "size": {
                                        "value": 45,
                                        "random": false,
                                        "anim": {
                                            "enable": false,
                                            "speed": 10,
                                            "size_min": 40,
                                            "sync": false
                                        }
                                    },
                                    "line_linked": {
                                        "enable": false,
                                        "distance": 200,
                                        "color": "#ffffff",
                                        "opacity": 1,
                                        "width": 2
                                    },
                                    "move": {
                                        "enable": true,
                                        "speed": 2,
                                        "direction": "none",
                                        "random": true,
                                        "straight": false,
                                        "out_mode": "bounce",
                                        "attract": {
                                            "enable": true,
                                            "rotateX": 1200,
                                            "rotateY": 1200
                                        }
                                    }
                                },
                                "interactivity": {
                                    "detect_on": "canvas",
                                    "events": {
                                        "onhover": {
                                            "enable": false,
                                            "mode": "repulse"
                                        },
                                        "onclick": {
                                            "enable": false,
                                            "mode": "push"
                                        },
                                        "resize": true
                                    },
                                    "modes": {
                                        "grab": {
                                            "distance": 400,
                                            "line_linked": {
                                                "opacity": 1
                                            }
                                        },
                                        "bubble": {
                                            "distance": 400,
                                            "size": 40,
                                            "duration": 2,
                                            "opacity": 8,
                                            "speed": 3
                                        },
                                        "repulse": {
                                            "distance": 80,
                                            "duration": 0.4
                                        },
                                        "push": {
                                            "particles_nb": 4
                                        },
                                        "remove": {
                                            "particles_nb": 2
                                        }
                                    }
                                },
                                "retina_detect": false
                            }

                        );




                    }


                    if ($('.banner-title').length) {

                        $('#fp-svg').lazylinepainter({
                            "svgData": pathObj,
                            "drawSequential": false,
                            "strokeWidth": 3,
                            "strokeColor": "#F2B02A",
                            'ease': 'easeInOutCubic',
                        }).lazylinepainter('paint');

                    }

                    if ($(window).width() > 1024) {
                        // var s = skrollr.init({
                        //     forceHeight: false,
                        //     smoothScrolling: true



                        // });

                    }


                    if ($('.no-geo').length) {


                        $('.local-events a').html('<i class="fa fa-calendar"></i> CHS Events');
                        $('.local-blog a').html('<i class="fa fa-pencil"></i> CHS Blog');
                        $('.local-news a').html('<i class="fa fa-newspaper-o"></i> CHS News');



                    }
                });


                //SKROLLR CODE POSSIBLE REMOVE
                // $(window).resize(function () {
                //     if ($(window).width() > 1024) {
                //         skrollr.init();

                //     }

                // });





            }
        }, // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
                //
                $('.down').click(function () {

                        // $.fn.fullpage.moveTo(2);
                        // return false;

                    })
                    //Home Page section Heights fpr fixed nav
                //SET SECTION HEIHGTS
                function homeInit() {
                    var winHeight = $(window).height();
                    var navHeight = $('.fp-main-menu').outerHeight();
                    var newHeight = winHeight - navHeight;
                    var cardHeight = newHeight / 3;
                    console.log(newHeight);
                    $('.home .section.fp-news').css('height', newHeight + 2);
                    $('.home .section.fp-news .fp-tableCell').css('height', newHeight + 2);
                    $('.post-card').css('height', cardHeight);

                    var headerHeight = $('.main-nav').outerHeight();
                    var windowHeight = $(window).height();
                    var remaining = windowHeight - headerHeight;

                    $("<style type='text/css'>.fp-viewing-chs-home .fp-main-menu { transform: translateY(" + remaining + "px);} </style>").appendTo("head");





                }

                homeInit();


                if ($(window).width() > 769) {



                    // $('#fullpage').fullpage({
                    //     responsiveWidth: 769,
                    //     anchors: ['chs-home', 'chs-search', 'chs-news', 'chs-footer'],
                    //     css3: true,
                    //     easing: 'easeInOutCubic',
                    //     easingcss3: 'ease',
                    //     lockAnchors: true,
                    //     scrollOverflow: true,
                    //     scrollOverflowEndPrevent: {
                    //         delay: 500,
                    //         reversal: false
                    //     },

                    //     afterLoad: function (anchorLink, index) {


                    //         var headerHeight = $('.main-nav').outerHeight();
                    //         var windowHeight = $(window).height();
                    //         var remaining = windowHeight - headerHeight;

                    //         var loadedSection = $(this);

                    //         if (index == 1) {
                    //             var delayMs = 1300; //1 second

                    //             setTimeout(function () {
                    //                 $('.fp-text').css('opacity', 1);
                    //                 $('.fp-text').css('margin-top', -100);
                    //             }, delayMs);


                    //         } else if (index == 2) {


                    //             $('#fp-svg').lazylinepainter({
                    //                 "svgData": pathObj,
                    //                 "drawSequential": false,
                    //                 "strokeWidth": 3,
                    //                 "strokeColor": "#F2B02A",
                    //                 'ease': 'easeInOutCubic',
                    //                 //										'duration': 2000,
                    //                 'onStrokeComplete': function () {

                    //                     $('.fp-about .content-col-about').css('transform', 'translateX(0)');

                    //                 }
                    //             }).lazylinepainter('paint');
                    //         } else {


                    //             $('#fp-svg').lazylinepainter('destroy');



                    //         }
                    //     },

                    // });



                } else {

                    var delayMs = 500; //1 second

                    setTimeout(function () {
                        $('.fp-text').css('opacity', 1);
                        $('.fp-text').css('margin-top', -100);
                    }, delayMs);

                }

                if ($('html.mobile').length) {
                    var $document = $(document),
                        $element = $('.fp-main-menu'),
                        className = 'scrolled';


                    $document.scroll(function () {
                        $element.toggleClass(className, $document.scrollTop() > 100);


                    });



                }

                // FIRES ON WINDOW RESIZE
                $(window).resize(function () {
                    homeInit();



                });




                //REMOVE
                particlesJS('particles-js',

                    {
                        "particles": {
                            "number": {
                                "value": 4,
                                "density": {
                                    "enable": false,
                                }
                            },
                            "color": {
                                "value": "#ffffff"
                            },
                            "shape": {
                                "type": ["image", "image2", "image3", "image4"],

                                "image": {
                                    "src": "/wp-content/themes/chs-childrens-home-society-web/dist/img/shapes/star.svg"
                                },
                                "image2": {
                                    "src": "/wp-content/themes/chs-childrens-home-society-web/dist/img/shapes/cir.svg"
                                },
                                "image3": {
                                    "src": "/wp-content/themes/chs-childrens-home-society-web/dist/img/shapes/tri.svg"
                                },
                                "image4": {
                                    "src": "/wp-content/themes/chs-childrens-home-society-web/dist/img/shapes/square.svg"
                                }
                            },
                            "opacity": {
                                "value": 1,
                                "random": true,

                            },
                            "size": {
                                "value": 45,
                                "random": false,

                            },
                            "line_linked": {
                                "enable": false,
                                "distance": 0,
                                "color": "#ffffff",
                                "opacity": 0,
                                "width": 0
                            },
                            "move": {
                                "enable": true,
                                "speed": 3,
                                "direction": "none",
                                "random": true,
                                "straight": false,
                                "out_mode": "bounce",
                                "attract": {
                                    "enable": true,
                                    "rotateX": 1200,
                                    "rotateY": 1200
                                }
                            }
                        },
                        "interactivity": {
                            "detect_on": "canvas",
                            "events": {
                                "onhover": {
                                    "enable": false,
                                    "mode": "repulse"
                                },
                                "onclick": {
                                    "enable": false,
                                    "mode": "push"
                                },
                                "resize": true
                            },

                        },
                        "retina_detect": true
                    }

                );



                var logoObj = {
                    "home_logo_path": {
                        "strokepath": [
                            {
                                "path": "M23.257,68.813c11.056,0,20.018-8.978,20.018-20.053H23.257V25.355c-11.98,0-21.691,9.728-21.691,21.729  S11.277,68.813,23.257,68.813z",
                                "duration": 800
            },
                            {
                                "path": "M34.943,25.699c5.455,0,9.877,4.43,9.877,9.894s-4.422,9.894-9.877,9.894s-9.877-4.43-9.877-9.894  S29.488,25.699,34.943,25.699z",
                                "duration": 800
            },
                            {
                                "path": "M66.831,48.76V11.026C66.832,5.489,62.35,1,56.823,1c-5.528,0-10.009,4.489-10.009,10.026v57.777  C57.866,68.803,66.826,59.83,66.831,48.76z",
                                "duration": 800
            },
                            {
                                "path": "M79.319,68.803c5.525,0,10.004-4.484,10.009-10.017V45.408c0-11.075-8.962-20.053-20.018-20.053v33.431l0,0  C69.316,64.319,73.795,68.803,79.319,68.803z",
                                "duration": 800
            },
                            {
                                "path": "M 82.112,24.6 L 89.787,19.074 97.461,24.66 94.53,15.622 102.204,10.037 92.718,10.037 89.787,1   86.855,10.037 77.369,10.037 85.044,15.622 z",
                                "duration": 800
            },
                            {
                                "path": "M96.585,30.964c-7.008,7.02-7.008,18.402,0,25.422l25.378-25.422C114.955,23.944,103.593,23.944,96.585,30.964z  ",
                                "duration": 800
            },
                            {
                                "path": "M92.333,63.824c7.008,7.021,18.37,7.021,25.378,0c7.008-7.02,7.008-18.402,0-25.422L92.333,63.824z",
                                "duration": 800
            }
                            ],
                        "dimensions": {
                            "width": 125,
                            "height": 71
                        }
                    }
                };



                $('#home_logo_path').lazylinepainter({
                    "svgData": logoObj,
                    "strokeWidth": 1.5,
                    "strokeColor": "#F2B027",
                    "drawSequential": false,
                    'onStrokeComplete': function () {
                        setTimeout(function () {
                            $('body').removeClass('loading');
                        }, 300);




                    }

                }).lazylinepainter('paint');





            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS

                $(window).load(function () {

                    var headerHeight = $('.main-nav').outerHeight();
                    var windowHeight = $(window).height();
                    var remaining = windowHeight - headerHeight;

                    $("<style type='text/css'>.fp-viewing-chs-home .fp-main-menu { transform: translateY(" + remaining + "px);} </style>").appendTo("head");

                });


                if (($('.ios').length) && ($(window).width() < 570)) {




                    $('.particle-container').height($.windowHeight({
                        viewport: "visual"
                    }) - 33);
                    //					$('.particle-container').height($(window).height());
                }







            }
        }, // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        },
        'page_template_template_leaders': {
            init: function () {
                // JavaScript to be fired on the about us page


                $('.img-col-leaders img').matchHeight({
                    target: $('.content-col-leaders')
                });
            }
        },
        'page_template_template_locations': {
            init: function () {
                var locations = new LocationsMap('.florida-map path');

                $('.trigger').click(function () {
                    var modalLink = $(this).attr('data-modal');
                    var modalOpen = $('.open-' + modalLink);

                    modalOpen.toggleClass('open');
                    $('body').toggleClass('modal-open');



                    if ($(window).width() < 768) {


                        var windowHeight = $.windowHeight();
                        var topheight = $('.open-' + modalLink).find('.flex-row').outerHeight();
                        var remaining = windowHeight - topheight;


                        $('.open-' + modalLink + ' .section-local-board').css('height', remaining);





                    }




                    return false;
                });


//                $('.modal-image img').matchHeight({
//                    target: $('.watch')
//                });




                var options = {
                    valueNames: ['county-link']
                };

                var countyList = new List('search-county-list', options);

                countyList.sort('county-link', {
                    order: "asc"
                });
                $('#search-county-list input').keypress(function () {
                    $('#search-county-list ul').slideDown(500);


                });


                $('.location-toggle').click(function () {

                    if (Cookies.get('primary-location') == undefined) {


                        getCHSLocation();


                        setTimeout(function () {

                            var chsRegionName = $('.locations-menu').find('li[data-toggle="' + Cookies.get('primary-location') + '"]').attr('data-name');
                            var chsRegionLowers = chsRegionName.replace(/\s+/g, '-').toLowerCase();

                            $('#search-county-list input').slideUp(0);
                            $('#' + chsRegionLowers + '.outer-location-card').delay(100).slideDown(0).addClass('flipped').css('z-index', '1');


                            setTimeout(function () {
                                $('html, body').animate({
                                    scrollTop: $('#' + chsRegionLowers).offset().top - 80
                                }, 500)


                                $('#' + chsRegionLowers + '.outer-location-card').css('z-index', '2');

                            }, 500);



                        }, 2000);



                    } else {


                        setTimeout(function () {

                            var chsRegionName = $('.locations-menu').find('li[data-toggle="' + Cookies.get('primary-location') + '"]').attr('data-name');
                            var chsRegionLowers = chsRegionName.replace(/\s+/g, '-').toLowerCase();

                            $('#search-county-list input').slideUp(0);
                            $('#' + chsRegionLowers + '.outer-location-card').delay(100).slideDown(0).addClass('flipped').css('z-index', '1');


                            setTimeout(function () {
                                $('html, body').animate({
                                    scrollTop: $('#' + chsRegionLowers).offset().top - 80
                                }, 500)


                                $('#' + chsRegionLowers + '.outer-location-card').css('z-index', '2');

                            }, 500);



                        }, 500);


                    }




                });

                $('#search-county-list .county-link').click(function (e) {

                    e.preventDefault(e);

                    var localCHS = $(this).parent('li').attr('data-name');
                    var chslowers = localCHS.replace(/\s+/g, '-').toLowerCase();


                    $('#' + chslowers + '.outer-location-card').delay(100).slideDown(500).addClass('flipped');
                    $('#search-county-list ul').slideUp(0);

                    setTimeout(function () {
                        $('html, body').animate({
                            scrollTop: $('#' + chsRegionLowers).offset().top - 80
                        }, 1000)

                    }, 500);



                    return false;

                });





            }
        },
        'page_id_14': {
            init: function () {


                $('.outer-location-card .chs-btn-purp').click(function () {

                    var chsRegion = $(this).attr('data-toggle');
                    var closestCounty = $('.locations-menu .list li[data-name="' + chsRegion + '"]').attr('data-toggle');
                    Cookies.remove('primary-location');
                    Cookies.set('primary-location', closestCounty, {
                        expires: 365
                    });
                    Cookies.remove('chs-location');
                    Cookies.set('chs-location', chsRegion, {
                        expires: 365
                    });
                    console.log(chsRegion);


                });

            }
        },
        'single_events': {
            init: function () {

                $('.img-single-event img').first().css('height', $('.img-single-event:first-of-type img').outerWidth());
                $('.img-single-event img').last().css('height', $('.img-single-event:first-of-type img').outerWidth());

                $(window).resize(function () {
                    $('.img-single-event img').first().css('height', $('.img-single-event:first-of-type img').outerWidth());
                    $('.img-single-event img').last().css('height', $('.img-single-event:first-of-type img').outerWidth());

                });
                if ($(window).width() > 768) {
                    $('.post-col').matchHeight({
                        target: $('.watch')
                    });

                }
            }
        },
        'single_post': {
            init: function () {

                $('.img-single-event img').first().css('height', $('.img-single-event:first-of-type img').outerWidth());
                $('.img-single-event img').last().css('height', $('.img-single-event:first-of-type img').outerWidth());


                $(window).resize(function () {
                    $('.img-single-event img').first().css('height', $('.img-single-event:first-of-type img').outerWidth());
                    $('.img-single-event img').last().css('height', $('.img-single-event:first-of-type img').outerWidth());

                });


                if ($(window).width() > 768) {
                    $('.post-col').matchHeight({
                        target: $('.watch')
                    });

                }


                $('.shares .pop-link').click(function () {
                    var poplink = $(this).attr('href');
                    newwindow = window.open(poplink, 'name', 'height=400,width=600');
                    if (window.focus) {
                        newwindow.focus()
                    }
                    return false;

                });




            }
        },
        'single_leadership_blog': {
            init: function () {

                $('.img-single-event img').first().css('height', $('.img-single-event:first-of-type img').outerWidth());
                $('.img-single-event img').last().css('height', $('.img-single-event:first-of-type img').outerWidth());


                $(window).resize(function () {
                    $('.img-single-event img').first().css('height', $('.img-single-event:first-of-type img').outerWidth());
                    $('.img-single-event img').last().css('height', $('.img-single-event:first-of-type img').outerWidth());

                });


                if ($(window).width() > 768) {
                    $('.post-col').matchHeight({
                        target: $('.watch')
                    });

                }


                $('.shares .pop-link').click(function (e) {
                    e.preventDefault();
                    var poplink = $(this).attr('href');
                    newwindow = window.open(poplink, 'name', 'height=400,width=600');
                    if (window.focus) {
                        newwindow.focus()
                    }
                    return false;

                });



            }
        },
        'page_template_template_careers': {
            init: function () {

                $('.img-single-event img').last().css('height', $('.img-single-event:first-of-type img').outerWidth());

                $(window).resize(function () {
                    $('.img-single-event img').last().css('height', $('.img-single-event:first-of-type img').outerWidth());


                });

            }
        },
        'archive': {
            init: function () {


                $(window).load(function () {


                    if (Cookies.get('primary-location') == undefined) {




                    } else {


                        var chsRegion = Cookies.get('primary-location'),
                            chsLocal = Cookies.get('chs-location');
                        //						chsRegion = chsLocal.replace(/\s+/g, '-').toLowerCase();


                        //					$('.blog-card').not('.flex-fit[data-toggle="' + chsRegion +'"]').slideUp(0);
                        //					$('.blog-card[data-toggle="' + chsRegion +'"]').slideDown(0);
                        $('.event-location-select option[value="' + chsLocal + '"]').prop('selected', true);

                        console.log('Posts for - ' + Cookies.get('primary-location') + ' - ' + Cookies.get('chs-location'));
                    }


                    Cookies.remove('viewing-pr');


                })

                $('.event-location-select').on('change', function () {


                    var chsRegion = this.value;
                    var local_county = $('.locations-menu .list li[data-name="' + chsRegion + '"]').first().attr('data-toggle');
                    //					chsRegion = chsLocal.replace(/\s+/g, '-').toLowerCase();
                    //					$('.blog-card').not('.flex-fit[data-toggle="' + this.value + '"]').slideUp(0);
                    //					$('.blog-card[data-toggle="' + this.value + '"]').slideDown(0);


                    Cookies.remove('primary-location');
                    Cookies.set('primary-location', local_county, {
                        expires: 365
                    });
                    Cookies.remove('chs-location');
                    Cookies.set('chs-location', chsRegion, {
                        expires: 365
                    });
                    location.reload();

                })



                $('.featured-slider').slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    prevArrow: "<img class='a-right control-c prev slick-prev' src='../../../../wp-content/themes/chs-childrens-home-society-web/dist/img/prev-arrow.svg'>",
                    nextArrow: "<img class='a-right control-c next slick-next' src='../../../../wp-content/themes/chs-childrens-home-society-web/dist/img/right-arrow-blue.svg'>",

                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: true
                            }
                        }

                      ]



                });


            }
        },
        'page_template_archive': {
            init: function () {


                $(window).load(function () {


                    if (Cookies.get('primary-location') == undefined) {




                    } else {


                        var chsRegion = Cookies.get('primary-location'),
                            chsLocal = Cookies.get('chs-location');


                        $('.event-location-select option[value="' + chsLocal + '"]').prop('selected', true);

                        console.log('Posts for - ' + Cookies.get('primary-location') + ' - ' + Cookies.get('chs-location'));
                    }




                })

                $('.event-location-select').on('change', function () {


                    var chsRegion = this.value;
                    var local_county = $('.locations-menu .list li[data-name="' + chsRegion + '"]').first().attr('data-toggle');




                    Cookies.remove('primary-location');
                    Cookies.set('primary-location', local_county, {
                        expires: 365
                    });
                    Cookies.remove('chs-location');
                    Cookies.set('chs-location', chsRegion, {
                        expires: 365
                    });
                    location.reload();

                })



                $('.featured-slider').slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    prevArrow: "<img class='a-right control-c prev slick-prev' src='../../../../wp-content/themes/chs-childrens-home-society-web/dist/img/prev-arrow.svg'>",
                    nextArrow: "<img class='a-right control-c next slick-next' src='../../../../wp-content/themes/chs-childrens-home-society-web/dist/img/right-arrow-blue.svg'>",

                    responsive: [
                        {
                            breakpoint: 768,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                dots: true
                            }
                        }


                      ]



                });


            }
        },
        'page_id_237': {
            init: function () {

                $('.quote-slider').slick({
                    infinite: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    dots: true,
                    prevArrow: "<img class='a-right control-c prev slick-prev' src='../../../../wp-content/themes/chs-childrens-home-society-web/dist/img/prev-arrow.svg'>",
                    nextArrow: "<img class='a-right control-c next slick-next' src='../../../../wp-content/themes/chs-childrens-home-society-web/dist/img/right-arrow.svg'>",
                });

            }
        },
        'services_template_default': {
            init: function () {


                // if ($('.no-image').length) {



                // } else {
                //     stuckPadding();

                //     $(window).resize(function () {
                //         stuckPadding()

                //     });

                //     function stuckPadding() {

                //         var sticky = new Waypoint.Sticky({
                //             element: $('.img-col-service')[0],
                //             wrapper: false
                //         })

                //         if ($(window).width() > 993) {

                //             var containerWidth = $('body').find('.container').first().outerWidth();
                //             var windowWidth = $(window).width();
                //             var half = ((windowWidth - containerWidth) / 2) + 15;

                //             $("<style type='text/css'>.img-col-service.stuck { padding-right: " + half + "px}</style>").appendTo('head');

                //         } else {

                //             $("<style type='text/css'>.img-col-service.stuck { padding: 0 15px;}</style>").appendTo('head');
                //         }


                //     }

                // }



                $('.form-select').on('change', function () {


                    console.log($(this).find(":selected").text());

                    $('.loc-form select option[value="' + $(this).find(":selected").text() + '"]').prop('selected', true);
                    $('.form-container').slideDown(500);
                    return false;


                });

                if (Cookies.get('primary-location') == undefined) {

                } else {


                    $(window).load(function () {

                        var chsRegion = Cookies.get('primary-location'),
                            chsLocal = Cookies.get('chs-location');

                        console.log(chsLocal);

//                        $('.form-select option[value="' + chsLocal + '"]').prop('selected', true);
                        $('.loc-form select option[value="' + chsLocal + '"]').prop('selected', true);
//                        $('.form-container').slideDown(500);


                    })



                }


            }
        },
        'single_locations': {
            init: function () {


                //                if ($(window).width() > 993) {
                //                stuckPadding();
                //                }
                //
                //
                //                $(window).resize(function () {
                //
                //                    stuckPadding();
                //
                //
                //
                //                });
                //
                //
                //
                //
                //                function stuckPadding() {
                //
                //                var sticky = new Waypoint.Sticky({
                //                    element: $('.img-col-service')[0],
                //                    wrapper: false
                //                })
                //
                //
                //
                //
                //                    var containerWidth = $('body').find('.container').first().outerWidth();
                //                    var windowWidth = $(window).width();
                //                    var half = ((windowWidth - containerWidth) / 2) + 15;
                //
                //                    $("<style type='text/css'>.img-col-service.stuck { padding-right: " + half + "px}</style>").appendTo('head');
                //
                //
                //                }



            }
        }
    };
    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';
            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');
            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });
            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };
    // Load Events
    $(document).ready(UTIL.loadEvents);
})(jQuery); // Fully reference jQuery after this point.
