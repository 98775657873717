/* 
 * Lazy Line Painter - Path Object 
 * Generated using 'SVG to Lazy Line Converter'
 * 
 * http://lazylinepainter.info 
 * Copyright 2013, Cam O'Connell  
 *  
 */ 
 
var pathObj = {
    "fp-svg": {
        "strokepath": [
            {
                "path": "M234.95,211.55c97.52,0,176.58-75.62,176.58-168.91H234.95Z",
                "duration": 1000
            },
            {
                "path": "M849.54,212.19c-97.52,0-176.59,75.62-176.59,168.91H849.54Z",
                "duration": 2000
            },
            {
                "path": "M176.59,431.49C79.06,431.49,0,507.12,0,2000.4H176.59Z",
                "duration": 3000
            },
            {
                "path": "M667.74,84.27c-97.52,0-176.58,75.62-176.58,168.91H667.74Z",
                "duration": 1000
            },
            {
                "path": "M624,364.86c97.52,0,176.58-75.62,176.58-168.91H624Z",
                "duration": 2000
            },
            {
                "path": "M124.38,505C221.91,505,301,429.34,301,336.06H124.38Z",
                "duration": 3000
            },
            {
                "path": "M294.45,627.56c0,47.17-40,85.4-89.29,85.4s-89.29-38.24-89.29-85.4,40-85.4,89.29-85.4S294.45,580.4,294.45,627.56Z",
                "duration": 1000
            },
            {
                "path": "M1028,213.33c0,47.17-40,85.4-89.29,85.4s-89.29-38.23-89.29-85.4,40-85.4,89.29-85.4S1028,166.16,1028,213.33Z",
                "duration": 2000
            },
            {
                "path": "M194.52,137.18c0,47.17-40,85.4-89.29,85.4s-89.29-38.24-89.29-85.4,40-85.4,89.29-85.4S194.52,90,194.52,137.18Z",
                "duration": 3000
            },
            {
                "path": "M636.78,91.5c0,47.17-40,85.4-89.29,85.4S458.2,138.66,458.2,91.5s40-85.4,89.29-85.4S636.78,44.33,636.78,91.5Z",
                "duration": 1000
            },
            {
                "path": "M 127.57 293.69 300.87 293.69 300.87 127.92 127.57 127.92 127.57 293.69z",
                "duration": 2000
            },
            {
                "path": "M 364.65 362.73 537.95 362.73 537.95 196.96 364.65 196.96 364.65 362.73z",
                "duration": 3000
            },
            {
                "path": "M 343.04 74.11 365.05 138.89 436.25 138.89 378.64 178.93 400.65 243.71 343.04 203.67 285.43 243.71 307.44 178.93 249.83 138.89 321.04 138.89 343.04 74.11z",
                "duration": 1000
            },
            {
                "path": "M 776.79 0 798.8 64.78 870 64.78 812.39 104.81 834.4 169.59 776.79 129.56 719.18 169.59 741.19 104.81 683.58 64.78 754.79 64.78 776.79 0z",
                "duration": 2000
            },
            {
                "path": "M 94.27 287.32 116.28 352.1 187.49 352.1 129.88 392.14 151.88 456.92 94.27 416.88 36.67 456.92 58.67 392.14 1.06 352.1 72.27 352.1 94.27 287.32z",
                "duration": 3000
            },
            {
                "path": "M756,503.95c-97.52,0-176.59-75.62-176.59-168.91H756Z",
                "duration": 1000
            },
            {
                "path": "M512.53,530c0,93.29-79.06,168.91-176.58,168.91V530Z",
                "duration": 2000
            },
            {
                "path": "M335.94,309.66c97.52,0,176.58,75.62,176.58,168.91H335.94Z",
                "duration": 3000
            },
            {
                "path": "M667.74,797.37c-97.52,0-176.58-75.62-176.58-168.91H667.74Z",
                "duration": 1000
            },
            {
                "path": "M572.06,581.11c-97.52,0-176.59-75.62-176.59-168.91H572.06Z",
                "duration": 2000
            },
            {
                "path": "M721.85,556.49c0,47.17,40,85.4,89.29,85.4s89.29-38.24,89.29-85.4-40-85.4-89.29-85.4S721.85,509.33,721.85,556.49Z",
                "duration": 3000
            },
            {
                "path": "M233.88,335.16c0,47.17,40,85.4,89.28,85.4s89.29-38.23,89.29-85.4-40-85.4-89.29-85.4S233.88,288,233.88,335.16Z",
                "duration": 1000
            },
            {
                "path": "M 822.85 521.11 996.15 521.11 996.15 355.35 822.85 355.35 822.85 521.11z",
                "duration": 2000
            },
            {
                "path": "M 214.75 613.5 388.05 613.5 388.05 447.74 214.75 447.74 214.75 613.5z",
                "duration": 3000
            },
            {
                "path": "M 626.17 713 799.47 713 799.47 547.24 626.17 547.24 626.17 713z",
                "duration": 1000
            },
            {
                "path": "M 617.33 386.82 595.32 451.6 524.11 451.6 581.72 491.63 559.72 556.41 617.33 516.38 674.93 556.41 652.93 491.63 710.54 451.6 639.33 451.6 617.33 386.82z",
                "duration": 2000
            }
        ],
        "dimensions": {
            "width": 1028,
            "height": 798
        }
    }
}; 
 