/**
 * Locations.js
 *
 */

var LocationsMap = (function (elem) {
    if (!elem) throw new Error('You must provided a selector');
    var _map_path = $(elem);

    function highlight_region(ident) {
        var _ident = ''.concat('#', ident);
        //    _map_path.attr('class', 'active');
        $('.initial').fadeOut(300);
        $('.outer-location-card').not(_ident).removeClass('flipped');
        $('svg path').not(_ident).attr('class', '');
        $('svg ' + _ident).attr('class', 'active');
        $(_ident).addClass('flipped');
    }

    

   
    _map_path.on('mouseenter', function () {        
        var regionName = $(this).attr('id');
        if (!regionName || regionName[0] === 'F') {
            regionName = $(this).parent().attr('class');
            $('#' + regionName).css('fill','#ffcd05');
        } else {
            $('#' + regionName).css('fill','#ffcd05');
        }

    });

    _map_path.on('mouseleave', function () {        
        var regionName = $(this).attr('id');
        if (!regionName || regionName[0] === 'F') {
            regionName = $(this).parent().attr('class');
            $('#' + regionName).css('fill','#dbdbdb');
        } else {
            $('#' + regionName).css('fill','#dbdbdb');
        }

    });

    _map_path.on('click', function () {
        // You know what, let's just do it dirty NEXT DEV NOTE: I expanded and made it dirtier
        console.log('map clicked');
        var regionName = $(this).attr('id');
        // console.log('initial region name: ' + regionName);
        if (!regionName || regionName[0] === 'F') {
          regionName = $(this).parent().attr('class');
          // console.log($(this).parent());
        }

        console.log('region clicked: ' + regionName);

        window.location.href = regionName;

        



        /* JAMES NOTE: 
            - if region IDs do not match location page slugs will need a switch statement here */

        // return highlight_region(regionName);
    });



});




window.getCHSLocation = function () {


    var currentlocation = Cookies.get('primary-location');

    if (currentlocation == undefined) {

        //									function getLocation() {
        //alert('Start');
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);


        } else {


            console.log("Geolocation is not supported by this browser.");
        }
        //									}
        //
        function showPosition(position) {
            //alert('Shitty Geolocate');

            console.log('hello....');

            var html = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + position.coords.latitude + ',' + position.coords.longitude + '&sensor=false';
            var county_name = '';




            $.get(html, function (response) {


                response.results.forEach(function (result) {

                    result.address_components.forEach(function (comp) {

                        if (comp.types[0] == 'administrative_area_level_2') {

                            //if this is found from google we have the county name
                            county_name = comp.long_name;

                        }

                    });

                });


                var chsRegionURL = $('.locations-menu').find('li[data-toggle="' + county_name + '"] a').attr('href');
                var chsRegionName = $('.locations-menu').find('li[data-toggle="' + county_name + '"]').attr('data-name');
                var chsRegionPhone = $('.locations-menu').find('li[data-toggle="' + county_name + '"] a').attr('data-src');

                console.log('Loading via GPS');
                Cookies.remove('primary-location');

                Cookies.set('primary-location', county_name, {
                    expires: 365
                });
                Cookies.remove('chs-location');
                Cookies.set('chs-location', chsRegionName, {
                    expires: 365
                });
                //

                $(".location_enter").html('<span>' + chsRegionName + '</span> <i class="fa fa-close"></i>');
                $(".local-office a, .local-office-link").attr('href', chsRegionURL);
                // $('.phone-btn').attr('href', 'tel:' + chsRegionPhone + '');
                // $('.phone-btn').html('Call Your Local Office<span class="number">' + chsRegionPhone + '</span>');
                $('body').removeClass('no-geo');
                $('#location_enter.location_enter.loading').removeClass('loading');
                $('.mobile-loc > a.loading').removeClass('loading');





            }, "json");


        }



    } else {


        var chsRegionURL = $('.locations-menu').find('li[data-toggle="' + Cookies.get('primary-location') + '"] a').attr('href');
        var chsRegionName = $('.locations-menu').find('li[data-toggle="' + Cookies.get('primary-location') + '"]').attr('data-name');
        var chsRegionPhone = $('.locations-menu').find('li[data-toggle="' + Cookies.get('primary-location') + '"] a').attr('data-src');

        $(".location_enter").html('<span>' + chsRegionName + '</span> <i class="fa fa-close"></i>');
        $(".local-office a, .local-office-link").attr('href', chsRegionURL);
        // $('.phone-btn').attr('href', 'tel:' + chsRegionPhone + '');
        // $('.phone-btn').html('Call Your Local Office<span class="number">' + chsRegionPhone + '</span>');
        $('body').addClass('no-geo');

        console.log('Loading via Cookie - ' + Cookies.get('primary-location'));
    }





};
